.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.MuiInput-underline:before {
  border: none !important;
}

.pac-container { 
  max-width: 480px !important; 
  top: 50px !important;
  margin: 5px;
} 

.pac-item {
  padding: 0.5em;
} 
.pac-matched {
  padding-left: 2px;
  padding-right: 2px;
  background-color: #FFECB3 !important;
  color: #666666;
  box-shadow: rgba(0, 0, 0, 0.117647) 0px 1px 6px, rgba(0, 0, 0, 0.117647) 0px 1px 4px;
  font-size: 110%;
  font-weight: bold;
  line-height: normal;
}

.infowindow-header {
  padding: 10pt;
  font-size: 11pt;
  text-align: left;
  background-color: #48B4E9;
  color: white;
  overflow-wrap: normal;
  font-weight: 400;
  border-radius: 4px 4px 0 0;
}

.infowindow-content {
  padding: 10pt;  
  color: rgb(26, 26, 26);
  line-height: 22px;
  font-size: 10.5pt;
}

/* tooltip heading */
.infowindow-changeHead {
  display: flex;
  position: relative;
}

.infowindow-changeBg {
  width: 100%;
  height: 38px;
  opacity: 0.5;
  position: absolute;
}

.infowindow-changeText {
  padding: 8pt 10pt 6pt 10pt;
  color: rgb(26, 26, 26);
  z-index: 9999;
  font-size: 10.5pt;
}

.infowindow-service-header {
  padding: 5pt 10px 5pt 10pt;
  font-size: 11pt;
  text-align: left;
  background-color: #48B4E9;
  color: white;
  overflow-wrap: normal;
  font-weight: bold;
}

.infowindow-service-content {
  padding: 5pt 10px 5pt 5pt;
  color: rgb(103, 102, 102);
}

.gm-style-iw {
  padding: 0px !important; 
}

.gm-style-iw-d {
  overflow: hidden !important;
}

.status {
  background-color: #ffeb3b !important;
  color: rgb(26, 26, 26);
}

.gain {
  background-color: #03F149 !important;
  color: rgb(26, 26, 26);
}

.loss{
  background-color: #F50011 !important; 
  color: rgb(26, 26, 26);
}

.table-container {
  max-height: 350px;
  overflow: scroll;
}
.services {
  font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

.services td {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  padding: 12px 10px;
}

/* .services tr:nth-child(even){background-color: #f2f2f2;} */

.services tr:hover {background-color: rgba(0, 0, 0, 0.12);}

.services th {
  padding: 12px 20px 12px 10px;
  text-align: left;
  background-color: #3f51b5;
  color: white;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 11pt;
}

.gm-style-iw .gm-style-iw-c {
  max-height: 40px !important;
}

.gm-style-iw {
  min-width: 350px !important;
  max-width: 400px !important;
  /*top: 15px !important;*/
  /*left: 0px !important;*/
}
#iw-container {
  margin-bottom: 10px;
}
#iw-container .iw-title {
  font-family: 'Open Sans Condensed', sans-serif;
  font-size: 22px;
  /*font-weight: 400;*/
  /*padding: 10px;*/
  background-color: white;
  color: white;
  margin: 0;
  border-radius: 2px 2px 0 0;
}
#iw-container .iw-content {
  font-size: 13px;
  line-height: 18px;
  /*font-weight: 400;*/
  margin-right: 1px;
  /*padding: 15px 5px 20px 15px;*/
  max-height: 140px;
  overflow-y: auto;
  overflow-x: hidden;
}

.gm-ui-hover-effect{
  width: 20px;
  height: 20px;
}

/* popup cross close */
button.gm-ui-hover-effect {
  right: -4px !important;       
  top: -4px !important;
}

.gm-style .gm-style-iw-c {
  border-radius: 4px;

  /* manual mui elevation 2 */
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
}

/* popup arrow */
.gm-style .gm-style-iw-t::after {
  background: linear-gradient(45deg,rgba(255,255,255,1) 50%,rgba(255,255,255,0) 51%,rgba(255,255,255,0) 100%);
}