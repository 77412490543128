.root {
   flex-grow: 1;
} 
.title {
    flex-grow: 1;
 } 
.header {
    width: 99% !important;
}
.avatar { 
    z-index: 300; 
    position: relative;
    float: right; 
    display: flex; 
    padding: 5px !important; 
}
 